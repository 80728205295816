<template>
  <v-btn
    color="primary"
    v-bind="$attrs"
    :loading="isLoading"
    :disabled="isLoading"
    @click="download"
  >
    {{ titleText }}
    <v-icon right>
      mdi-download
    </v-icon>
  </v-btn>
</template>

<script>
import { downloadMaterials } from '@/lib/download'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    path: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      default: ''
    },
    trackingEvent: {
      type: String,
      default: ''
    },
    trackingData: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    titleText () {
      return this.title || this.$t('labels.materials-download')
    }
  },
  methods: {
    async download () {
      this.isLoading = true
      try {
        window.Appcues.track(this.trackingEvent, this.trackingData)
        await downloadMaterials(this.path, this.filename)
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.landingpages.download.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
